import React, { useState } from "react"
import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Arrow from "../../images/icons/down-arrow.svg"

const CallAction = styled.div`
  width: 100%;
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #82a0bc;
  border-top: 2px solid #82a0bc;

  @media ${props => props.theme.sizes.tablet} { 
    transform: translateY(-0.5rem);
  }
  @media ${props => props.theme.sizes.laptopL} { 
    transform: translateY(0rem);
  }
`

const ArrowDown = styled(Arrow)`
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: -50%;
`

const StyledAnchor = styled(AnchorLink)`
  font-size: 1.3rem;
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: 700;
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  transition: all 0.4s;
  padding-bottom: 20px;

  @media ${props => props.theme.sizes.tablet} { 
    font-size: 1.3rem;
    padding-top: 0.1rem;
  }
  @media ${props => props.theme.sizes.laptopL} { 
    font-size: 2rem;
    padding-bottom: 20px;  
  }

  svg {
    transition: all 0.44s;
    width: 2rem;

    @media ${props => props.theme.sizes.laptopL} { 
      width: unset;
    }
  }

  &:hover {
    letter-spacing: 3px;
    svg {
      bottom: -40%;

      @media ${props => props.theme.sizes.tablet} { 
        bottom: -70%;
      }
    }
  }
`

export default function CallToOffer() {
  const [pos, setPosition] = useState()


  return (
    <section
      id="CallToOffer"
      style={{
        display: pos === "796.4375" ? "zero" : "block",
      }}
    >
      <CallAction>
        <StyledAnchor to="/#kolonie">
          Sprawdź ofertę!
          <ArrowDown />
        </StyledAnchor>
      </CallAction>
    </section>
  )
}
