import React from "react"
import styled from "styled-components"

import Icon from "../../images/icons/cloud1.svg"
import Cloud1 from "../../images/icons/cloud2.svg"
import Cloud2 from "../../images/icons/cloud3.svg"

const CloudsWrapper = styled.div`
  background: transparent;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
`

const MovingCloudFirst = styled.div`
  position: absolute;
  top: ${props => props.top};
  width: ${props => props.width};
  z-index: 10;
  left: 0;
  animation: ${props => props.keyframe} 90s linear infinite alternate;

  svg {
    max-width: 70%;
  }

  @keyframes drift {
    from {
      left: 0;
    }

    to {
      left: 100%;
    }
  }

  @keyframes driftSecond {
    from {
      left: 30%;
    }

    to {
      left: 100%;
    }
  }

  @keyframes driftaway {
    from {
      left: 100%;
    }

    to {
      left: 0%;
    }
  }
`
const MovingCloudSecond = styled.div`
  position: absolute;
  width: 19%;
  top: 10%;
  z-index: 10;
  left: 100%;
  animation: driftaway 90s linear infinite;

  @keyframes driftaway {
    from {
      left: 100%;
    }

    to {
      left: 0%;
    }
  }
`

const Clouds = () => {
  return (
    <CloudsWrapper>
      <MovingCloudFirst width="18%" keyframe="drift" top="2%">
        <Cloud1 />
      </MovingCloudFirst>
      <MovingCloudFirst width="21%" keyframe="driftaway" top="13%">
        <Icon />
      </MovingCloudFirst>
      <MovingCloudFirst width="19%" keyframe="driftSecond" top="40%">
        <Cloud2 />
      </MovingCloudFirst>
    </CloudsWrapper>
  )
}

export default Clouds
