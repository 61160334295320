import styled from "styled-components"

export const OfferSection = styled.section`
  padding: 30% 5% 5%;

  @media ${props => props.theme.sizes.tablet} {
    padding: 5% 5% 0%;
  }
`
export const OfferText = styled.section`
  font-family: ${props => props.theme.fonts.secondary};
  color: ${props => props.theme.colors.primary};
  font-size: 1.2rem;
  text-align: center;
  margin: 0 auto;
  padding: 0 4%;

  @media ${props => props.theme.sizes.tablet} {
    padding: 0 12%;
  }
`

export const OfferTitle = styled.h2`
  font-size: 2rem;
  font-family: ${props => props.theme.fonts.primary};
  font-weight: 500;
  text-align: center;
  color: ${props => props.theme.colors.secondary};

  @media ${props => props.theme.sizes.tablet} {
    font-size: 2.5rem;
  }
`

export const OfferItemTitle = styled.h3`
  font-size: 1.6rem;
  font-family: ${props => props.theme.fonts.primary};
  font-weight: 400;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 20px;
`

export const OfferWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1% 0;
`
export const OfferDescWrapper = styled.div`
  .OfferDate {
    font-family: ${props => props.theme.fonts.secondary};
    color: #263160;
    display: flex;
    flex-direction: column;
  }
  .OfferPrice {
    font-family: ${props => props.theme.fonts.secondary};
    color: #263160;

    .price {
      font-size: 25px;
      font-weight: 700;
      margin-left: 5px;
    }
  }
`

export const OfferDesc = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  padding: 0 5%;
`

export const OfferItem = styled.div`
  flex: 100%;
  max-width: 100%;
  padding: 20px 15px;

  @media ${props => props.theme.sizes.tablet} {
    flex: 0 50%;
  }

  @media ${props => props.theme.sizes.laptop} {
    flex: 0 50%;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  @media ${props => props.theme.sizes.laptopL} {
    flex: 0 33.3%;
    max-width: 33.3%;
  }
`
export const OfferImage = styled.figure`
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const OfferButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const OfferButton = styled.button`
  font-size: 14px;
  letter-spacing: 0.15em;
  font-family: ${props => props.theme.fonts.secondary};
  display: inline-block;
  height: 30px;
  line-height: 6px;
  padding: 20px;
  color: white;
  -webkit-transition: all 200ms;
  transition: all 200ms;
  position: relative;
  background: ${props => props.theme.colors.secondary};
  border-radius: 5px;
  display: inline-block;
  border: none;
  cursor: pointer;
  transition: all 0.4s ease 0s;

  &:hover {
    background: ${props => props.theme.colors.primary};
    letter-spacing: 0.4em;
    transition: all 0.4s ease 0s;
  }
`
