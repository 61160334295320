import React from "react"
import Layout from "../components/layout"

import About from "../components/sections/about"
import Banner from "../components/sections/banner"
import CallToOffer from "../components/sections/callToOffer"
import Offer from "../components/sections/offer"

const IndexPage = ({ data }) => (
  <Layout>
    <Banner
      title="„ Kiedy śmieje się dziecko, śmieje się cały świat” "
      signature="Janusz Korczak"
    />
    <CallToOffer />
    <Offer />
    <About />
  </Layout>
)

export default IndexPage
