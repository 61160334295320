import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

import Logo from "../elements/logo"
import Clouds from "../elements/clouds"

const Banner = props => {
  const Wrapper = styled.div`
    width: 100%;
    height: 85vh;
    display: flex;
    background: yellow;
    position: relative;
    overflow: hidden;

    @media ${props => props.theme.sizes.desktopL} { 
        transform: unset;
      }
  `

  const LogoWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 100;

    .gatsby-image-wrapper {
      transform: scale(0.45);

      @media ${props => props.theme.sizes.tablet} { 
        transform: scale(0.5);
      }
      @media ${props => props.theme.sizes.laptopL} { 
        transform: scale(0.7);
      }
    }



  `
  const Title = styled.h1`
    font-size: 1.8em;
    text-align: center;
    color: ${props => props.theme.colors.primary};
    font-family: ${props => props.theme.fonts.primary};
    z-index: 100;
    font-weight: 600;
    font-style: italic;
    display: flex;
    flex-direction: column;

    @media ${props => props.theme.sizes.tablet} { 
      font-size: 1.5em;
      margin-top: -4rem;
    }
    @media ${props => props.theme.sizes.laptopL} { 
      font-size: 2.5em;
      margin-top: 0rem;
    }

    .signature {
      font-weight: 300;
      margin-top: 1.5rem;
      font-size: 1.4rem;

      @media ${props => props.theme.sizes.tablet} { 
        font-size: 1.3rem;
      }
      @media ${props => props.theme.sizes.laptopL} { 
      font-size: 2rem;
      }
    }
  `
  const data = useStaticQuery(graphql`
    query Banner {
      image: file(relativePath: { eq: "bannerBeach2.jpg" }) {
        id
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid(maxWidth: 1970) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <section id="banner">
      <Wrapper>
        <Clouds />
        <Img
          fluid={data.image.childImageSharp.fluid}
          alt=""
          style={{ height: "100%", width: "100%" }}
        />
        <LogoWrapper>
          <Logo imgStyle={{ height: "500px", width: "500px" }} />
          <Title>
            <span>{props.title}</span>
            <div className="signature">{props.signature}</div>
          </Title>
        </LogoWrapper>
      </Wrapper>
    </section>
  )
}

export default Banner
