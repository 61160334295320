import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { OfferText, OfferTitle } from "./OffersStyles"

const AboutSection = styled.section`
  padding: 5%;

  .icons__title {
    font-size: 1.7rem;
    font-family: ${props => props.theme.fonts.primary};
    font-weight: 500;
    text-align: center;
    color: ${props => props.theme.colors.secondary};

    @media ${props => props.theme.sizes.tablet} {
      font-size: 2rem;
      margin: 4rem 0 1rem;
    }
  }
`

const AboutIcons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 5rem 0;

  @media ${props => props.theme.sizes.tablet} {
    flex-direction: row;
  }
`

const AboutIcon = styled.div`
  margin-bottom: 4rem;

  @media ${props => props.theme.sizes.tablet} {
    margin-bottom: unset;
  }
  .AI {
    &__image {
      width: 150px;
      margin: 0 auto;

      @media ${props => props.theme.sizes.tablet} {
        width: 150px;
        margin: 0 auto;
      }
    }
    &__text {
      font-size: 1.6rem;
      font-family: ${props => props.theme.fonts.secondary};

      font-weight: 400;
      text-align: center;
      color: ${props => props.theme.colors.secondary};
    }
  }
`

const About = props => {
  const data = useStaticQuery(graphql`
    query AboutSection {
      allWpPage(filter: { isFrontPage: { eq: true } }) {
        edges {
          node {
            id
            isFrontPage
            AcfHome {
              abouttext
              opis
              abouticons {
                podpis
                icon {
                  localFile {
                    extension
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const ACFAbout = data.allWpPage.edges[0].node.AcfHome

  console.log(ACFAbout)
  return (
    <AboutSection id="oNas">
      <OfferTitle>O nas</OfferTitle>
      <OfferText dangerouslySetInnerHTML={{ __html: ACFAbout.opis }} />
      <h3 className="icons__title">{ACFAbout.abouttext}</h3>
      <AboutIcons>
        {ACFAbout.abouticons.map(icon => (
          <AboutIcon>
            <figure className="AI__image">
              {icon.icon ? (
                <img
                  alt="zdjęcie kolonii"
                  src={icon.icon.localFile.publicURL}
                />
              ) : null}
            </figure>
            <div className="AI__text">{icon.podpis}</div>
          </AboutIcon>
        ))}
      </AboutIcons>
    </AboutSection>
  )
}

export default About
